<template>
  <div class="site-detail-box" v-if="showDetail">
    <header v-if="showHeader">
      <van-nav-bar
        :title="titleName"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
        <template #right>
          <icon-svg
            iconClass="iconshare-2"
            class="share"
            style="width: 20px; height: 20px; margin-left: 6px"
            @click="handleShare(item)"
          />
        </template>
      </van-nav-bar>
    </header>
    <!-- tabbar -->
    <van-tabs
      v-model:active="tabIndex"
      title-inactive-color="#666"
      title-active-color="#FC9834"
    >
      <van-tab title="工地详情">
        <!-- 工程详情 -->
        <div class="building-item">
          <div class="building-img">
            <!-- panoramicUrl -->
            <img
              :src="
                detail.headImgUrl
                  ? `${currentNet}/${detail.headImgUrl}`
                  : buildDetailImg
              "
              alt=""
              @click="loadPanoramic"
            />
          </div>
          <div class="building-info">
            <div class="info1">
              <div class="title-text">{{ detail.title }}</div>
              <!-- limitWords(detail.title, 20) -->
            </div>
            <div class="info2">
              <div class="room-describle">
                {{ detail.typeName }} | {{ detail.area }}m² |
                {{ detail.styleName }}
              </div>
              <div class="building-stage">
                <svg
                  style="width: 12px; height: 12px; line-height: 12px"
                  fill="#289FFF"
                  t="1648619964035"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2453"
                  width="200"
                  height="200"
                >
                  <path
                    d="M511.914 63.99c-247.012 0-447.925 200.912-447.925 447.924s200.913 447.925 447.925 447.925 447.925-200.913 447.925-447.925S758.926 63.989 511.914 63.989z m0 831.687c-211.577 0-383.763-172.186-383.763-383.763 0-211.577 172.014-383.763 383.763-383.763s383.763 172.014 383.763 383.763-172.186 383.763-383.763 383.763z"
                    fill=""
                    p-id="2454"
                  ></path>
                  <path
                    d="M672.06 511.914H512.085v-223.79c0-17.718-14.277-32.167-31.995-32.167-17.717 0-31.994 14.45-31.994 32.167V544.08c0 17.717 14.277 31.994 31.994 31.994H672.06c17.718 0 32.167-14.277 32.167-31.994-0.172-17.89-14.621-32.167-32.167-32.167z"
                    fill=""
                    p-id="2455"
                  ></path>
                </svg>
                {{ detail.stageName }}
              </div>
            </div>
            <div class="info3">
              <div class="location-text">
                <svg
                  t="1648621241158"
                  fill="#BFBFBF"
                  style="width: 12px; height: 12px"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3268"
                  width="200"
                  height="200"
                >
                  <path
                    d="M533.162667 846.813867a23.825067 23.825067 0 0 1-32.768 0C326.587733 682.0864 238.933333 550.570667 238.933333 448.477867a277.845333 277.845333 0 1 1 555.690667 0c0 102.126933-87.688533 233.608533-261.461333 398.336z m-246.613334-398.336c0 82.090667 76.288 199.2704 230.229334 348.091733 153.941333-148.821333 230.1952-266.001067 230.1952-348.0576a230.1952 230.1952 0 1 0-460.424534 0z m230.229334 87.3472a119.057067 119.057067 0 1 1 0-238.148267 119.057067 119.057067 0 0 1 0 238.148267z m0-47.650134a71.441067 71.441067 0 1 0 0-142.882133 71.441067 71.441067 0 0 0 0 142.882133z"
                    fill="#999999"
                    p-id="3269"
                  ></path>
                </svg>
                {{ detail.region }} {{ detail.apartmentName }}
              </div>
            </div>
          </div>
        </div>
        <!-- 名片 -->
        <van-sticky :offset-top="topSpace">
          <van-skeleton
            title
            avatar
            :row="3"
            v-if="showSkeleton"
          ></van-skeleton>
          <div v-else class="case-namecard" style="background-color: #fff">
            <div class="img-name" @click="loadUserCard">
              <img
                :src="
                  detail.userCard.headImgUrl
                    ? `${currentNet}/${detail.userCard.headImgUrl}`
                    : morenHeadImg
                "
                alt=""
              />
              <div class="creater-info">
                <p>{{ detail.userCard.name }}</p>
                <p>{{ detail.userCard.merchantName }}</p>
              </div>
            </div>
            <div class="create-describe">
              <span>{{
                isFold
                  ? limitWords(detail.userCard.selfIntro, 40)
                  : detail.userCard.selfIntro
              }}</span>
              <span
                style="color: #999"
                @click="handleIsFold"
                v-if="detail.userCard.selfIntro.length > 40"
                >{{ isFold ? "展开" : "&lt;&lt;折叠" }}</span
              >
            </div>
          </div>
        </van-sticky>
        <!-- 施工阶段 -->
        <div class="construction-stage">
          <div class="construction-stage-line"></div>
          <div class="construction-stage-box">
            <div class="construction-stage-name">施工阶段</div>
            <van-steps
              :active="tempStageIndex"
              active-icon="success"
              active-color="#38f"
            >
              <van-step
                v-for="(item, index) in detail.siteContentList"
                :key="index"
                @click="changeSteps(index, item)"
              ></van-step>
            </van-steps>
            <div class="steps-name">
              <div
                class="steps-name-text"
                v-for="(item, index) in detail.siteContentList"
                :key="index"
                :style="{
                  color: stageIndex == item.stageId ? '#333' : '#999',
                }"
              >
                {{ item.stageName }}
              </div>
            </div>
          </div>
          <div class="construction-stage-line"></div>
        </div>
        <!-- 施工进度\设计师\样板图片 -->
        <div class="construction-progress">
          <div
            class="progress-item-box"
            v-for="(item, index) in showConstructionProgress"
            :key="index"
          >
            <div class="header-img">
              <img
                :src="
                  item.headPortraitUrl
                    ? `${currentNet}/${item.headPortraitUrl}`
                    : morenHeadImg
                "
                alt=""
              />
            </div>
            <div class="progress-info-box">
              <div class="progress-info1">
                <div class="progress-info1-name">{{ item.userName }}</div>
                <div class="progress-info1-steps">{{ item.stageName }}</div>
              </div>
              <div class="progress-info2">{{ item.progress }}</div>
              <div class="progress-info3">
                {{ item.content }}
              </div>
              <div class="progress-info4">
                <img
                  :src="`${currentNet}/${img.url}`"
                  alt=""
                  v-for="(img, imgIndex) in item.fileDetailsList"
                  :key="imgIndex"
                  @click="myImagePreview(imgIndex, item.fileDetailsList)"
                />
              </div>
              <div class="progress-info5">{{ item.updateDate }}</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="传播轨迹">
        <!-- 传播轨迹 -->
        <div class="tack-container" v-if="clueList.length > 0">
          <div class="track-box" v-for="item in clueList" :key="item">
            <div class="user-img">
              <img
                :src="item.headImgUrl ? item.headImgUrl : morenHeadImg"
                alt=""
              />
            </div>
            <div class="track-details">
              <div>
                {{ item.name }}
                {{
                  item.phone
                    ? `${item.phone.slice(0, 3)}****${item.phone.slice(7)}`
                    : ""
                }}
              </div>
              <div>
                浏览时间：{{
                  item.browseTime
                    ? `${item.browseTime.slice(0, 10)} ${item.browseTime.slice(
                        11,
                        19
                      )}`
                    : ""
                }}
              </div>
              <div class="track-info">
                <div>浏览时长：{{ item.browseSecond }}秒</div>
                <div>浏览位置：{{ item.clueShareSource }}</div>
              </div>
              <div class="track-info">
                <div>分享人：{{ item.createName }}</div>
                <div>推荐人：{{ item.recommend_Name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 暂无轨迹 -->
        <div v-else class="no-clues">
          <img :src="nodataImg" />
        </div>
      </van-tab>
    </van-tabs>

    <footer></footer>
    <div class="share-or-menu">
      <div class="share_box">
        <div
          class="wrapperPoster"
          id="saveposter"
          style="z-index: 9999"
          v-if="showOverlay"
        >
          <div class="poster-details" v-if="showImg" ref="imageWrapper">
            <img
              :src="
                detail.headImgUrl
                  ? `${currentNet}/${detail.headImgUrl}`
                  : `${buildDetailImg}`
              "
              crossOrigin="anonymous"
            />
            <div class="text-qrcode">
              <div class="user-info-tag">
                <div class="user-info">
                  <!-- <img :src="headImgUrl" /> -->
                  <div class="left-text">
                    <p>{{ detail.title }}</p>
                    <p>分享给朋友</p>
                  </div>
                </div>
                <!-- <div class="user-tag">
                  <span
                    class="user-tag-item"
                    v-for="(it, index) in userTags"
                    :key="index"
                    >{{ it }}</span
                  >
                </div> -->
              </div>
              <div class="qrCodeUrl-all">
                <div class="qrCodeUrl-cls">
                  <img v-if="qrCodeUrl" :src="qrCodeUrl" />
                </div>
                <p>立即扫码</p>
                <p>了解更多内容</p>
              </div>
            </div>
          </div>
          <div v-else class="posterImg-hb">
            <img class="card-img" :src="imgSrc" ref="cardImgRef" />
          </div>
        </div>
        <van-share-sheet
          v-model:show="showShare"
          title="分享"
          :options="shareOptions"
          cancel-text="取消"
          @select="shareAction"
          safe-area-inset-bottom
        >
        </van-share-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, nextTick, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ImagePreview, Toast } from "vant";
import {
  findBuildSiteByIdApp,
  getBuildSiteDetailsUrl,
} from "@/api/newBuildSite";
import { getUserCardByPoster } from "@/api/user/userCard";
// import { findPosterImg } from "@/api/file";
import { createPosterAppShare, getQrCodeUrl, buildReprint } from "@/api/common";
import {
  copyStr,
  // loadImages,
  shareBuildToMiniProgram,
} from "@/utils/commonUtil";
import { posterShare } from "@/utils/posterShare";
import html2canvas from "html2canvas";

export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const state = reactive({
      titleName: "工地名称",
      currentNet: process.env.VUE_APP_WATCH_FILE_API,
      buildDetailImg: require("@/assets/images/buildSite-default.png"),
      morenHeadImg: require("../../assets/icons/Vector.png"),
      nodataImg: require("../../assets/images/no-data.png"),
      detail: {},
      showDetail: false,
      showShare: false,
      showOverlay: false,
      isFold: false,
      showSkeleton: false,
      tabIndex: 0, //轨迹和详情的index
      totalStageIndex: 0, //总的工程阶段
      stageIndex: 0, //自己选择的工程阶段
      tempStageIndex: 0,
      curretnStageIndex: 0, //目前工程阶段
      showConstructionProgress: [], //将要显示的施工进程详情
      options: [
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png") },
      ],
      posterOptions: [
        { name: "微信分享", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈分享", icon: require("@/assets/icons/friends2.png") },
      ],
      clueList: [],
      //====post===
      userForm: {},
      userTags: [],
      headImgUrl: "",
      showSharePoster: false,
      shareJumpUrl: "",
      posterId: "",
      imgSrc: "",
      qrCodeUrl: "",
      posterName: "",
      posterPic: "",
      sharePosterImg: "",
      showImg: true,
      shareOptions: [],
      typeListName: "",
      styleListName: "",
      buildId: route.query.buildId,
      //=====end=======
      topSpace: 43,
    });
    // 初始化详情
    const loadUserCard = () => {
      router.push(`/userCard`);
    };
    const handleBlack = () => {
      console.log("返回", route.params);
      router.back();
    };
    //================分享start=====================================
    watch(
      () => state.showShare,
      (newVal, oldVal) => {
        console.log("新值：", newVal, "老值", oldVal);
        if (state.showShare === false) {
          state.showOverlay = false;
          state.showImg = true;
        }
      }
    );
    const loadPoster = () => {
      savePoster();
      setTimeout(() => {
        Toast.clear();
      });
    };
    const handleShare = () => {
      // state.shareItem = e;
      console.log(state.shareItem, "分享", state.buildId);
      state.shareOptions = state.options;
      state.showShare = true;
      getUserCardByPoster().then((res) => {
        if (res) {
          if (res.headImgUrl) {
            state.headImgUrl = state.currentNet + "/" + res.headImgUrl;
          } else {
            state.headImgUrl = require("@/assets/poster_headerImg_default.png");
          }
          state.userForm = res;
          state.userTags = res.impressionLabel.split(",");
          if (state.userTags.length > 4) {
            state.userTags = state.userTags.slice(0, 4);
          }
        }
      });
      let params = {
        sourceId: state.buildId, //分享的id
        sourceType: 4, //来源类别 1-文章 2-案例 3-视频 4-工地
      };
      getQrCodeUrl(params).then((res) => {
        state.shareJumpUrl = res.jumpUrl;
        if (res.qrCodeUrl) {
          state.qrCodeUrl = res.qrCodeUrl;
        }
        state.showSharePoster = true;
      });
    };
    const shareAction = (e) => {
      console.log(e, "dianji");
      if (e.name === "微信分享" || e.name === "朋友圈分享") {
        if (state.showSharePoster === true) {
          Toast.loading({
            message: "正在\n打开微信...",
            forbidClick: true,
            duration: 3000,
            className: "posterToast",
          });
          let p = {
            sourceId: state.buildId, //分享的id
            sourceType: 4, //来源类别 1-文章 2-案例 3-视频 4-工地
            jumpUrl: state.shareJumpUrl,
          };
          createPosterAppShare(p).then((res) => {
            console.log("创建二海报", res);
          });
          let param = {
            imgSrc: state.imgSrc,
            id: state.buildId,
            bigPosterImgUrl: state.sharePosterImg,
          };
          setTimeout(() => {
            posterShare(e, param);
          });
        }
      } else if (e.name === "复制链接") {
        getBuildSiteDetailsUrl(state.buildId).then((res) => {
          console.log("链接详情", res);
          copyStr(res);
          state.showShare = false;
        });
      } else if (e.name === "分享海报") {
        if (state.showSharePoster === true) {
          Toast.loading({
            message: "生成海报中...",
            forbidClick: true,
            duration: 0,
            className: "posterToast",
          });
          state.shareOptions = state.posterOptions;
          // state.showImg = true
          state.showOverlay = true;
          // let arr = [];
          // arr.push(state.posterPic);
          // arr.push(state.qrCodeUrl);
          // arr.push(state.headImgUrl);
          // loadImages(arr, loadPoster);
          setTimeout(() => {
            loadPoster();
          }, 200);
        }
      } else {
        buildReprint({ busiId: state.buildId });
        shareBuildToMiniProgram(state.detail, e);
      }
    };
    //画图
    const savePoster = () => {
      console.log("保存");
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          useCORS: true,
          background: "#ffffff",
          height: document.getElementById("saveposter").scrollHeight,
          windowHeight: document.getElementById("saveposter").scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false;
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false;
          });
        });
      });
    };
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };
    //================分享end=====================================
    //全景漫游
    const loadPanoramic = () => {
      if (state.detail.panoramicUrl)
        router.push({
          name: "panoramicWindow",
          query: { panoramicUrl: state.detail.panoramicUrl },
        });
    };
    const limitWords = (e, num) => {
      if (e.length > num) {
        return e.substring(0, num) + "...";
      } else {
        return e;
      }
    };
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    const myImagePreview = (index, list) => {
      var imgList = [];
      list.forEach((element) => {
        var tempImg = `${state.currentNet}/${element.url}`;
        imgList.push(tempImg);
      });
      ImagePreview({
        images: imgList,
        closeable: true,
        startPosition: index,
      });
    };
    const changeSteps = (e, item) => {
      if (item.stageId <= state.curretnStageIndex) {
        state.stageIndex = item.stageId;
        state.tempStageIndex = e;
        state.showConstructionProgress = item.contentList;
      }
      console.log(e, state.curretnStageIndex, state.stageIndex);
    };
    const onLoad = async () => {
      let result = await findBuildSiteByIdApp(state.buildId);
      Toast.clear();
      state.detail = result;
      console.log("xiangqing", result);
      state.titleName = state.detail.title;
      state.showDetail = true;
      //控制名片会不会有展开和缩进
      state.detail.userCard.selfIntro.length > 40
        ? (state.isFold = true)
        : (state.isFold = false);
      //控制施工阶段有几个
      state.totalStageIndex = state.detail.siteContentList.length ?? 0;
      //控制施工阶段当前阶段
      state.curretnStageIndex = state.detail.stageId ?? 0;
      state.stageIndex = state.detail.stageId ?? 0;
      //控制初始化要显示的施工阶段进度详情
      state.detail.siteContentList.forEach((element, index) => {
        if (element.stageId == state.curretnStageIndex) {
          //主要内容和控制步骤点颜色是这步
          state.tempStageIndex = index;
          return (state.showConstructionProgress = element.contentList);
        }
        // console.log( state.stageIndex,"111111");
      });
      //传播轨迹列表
      state.clueList = state.detail.clueList;
      //
      //   const p = {
      //     busiId: state.buildId,
      //     busiCat: "buildSite",
      //     groupId: "sharePosterPic",
      //   };
      //   findPosterImg(p).then((res) => {
      //     console.log("分享图", res);
      //     if (res.length > 0) {
      //       //获取小图
      //       findPosterImg({
      //         busiId: state.buildId,
      //         busiCat: "buildSite",
      //         groupId: "sharePosterPic:S",
      //       }).then((sImg) => {
      //         state.sharePosterImg = sImg[0].url
      //           ? state.currentNet + "/" + sImg[sImg.length - 1].url
      //           : "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
      //       });
      //       state.posterPic = res[0].url
      //         ? state.currentNet + "/" + res[0].url
      //         : state.buildDetailImg;
      //     } else {
      //       state.posterPic = state.buildDetailImg;
      //       state.sharePosterImg =
      //         "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
      //     }
      //   });

      state.posterPic = state.detail.headImgUrl
        ? `${state.currentNet}/${state.detail.headImgUrl}`
        : "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
      state.sharePosterImg = state.detail.headImgUrl
        ? `${state.currentNet}/${state.detail.headImgUrl}`
        : "https://wca.ujiaku.com/upload/wx/buildSite_default.png";
    };
    console.log(state.currentNet);
    let showHeader = computed(() => route.query.hideTitleBar != 1);
    if (!showHeader.value) {
      state.topSpace = 0;
    }
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    onLoad();
    console.log(route.query.buildId, "buildid");
    return {
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      ...toRefs(state),
      handleBlack,
      handleShare,
      shareAction,
      limitWords,
      handleIsFold,
      myImagePreview,
      changeSteps,
      onLoad,
      loadUserCard,
      loadPanoramic,
      showHeader,
    };
  },
};
</script>

<style lang="scss" >
.posterToast {
  //color: #289fff;
  z-index: 1000000001 !important;
}

.van-tabs__line {
  background-color: #fc9834;
}
.van-share-sheet__options {
  justify-content: center;
  padding: 10px 16px 16px 16px;
}
.site-detail-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.site-detail-box {
  .building-item {
    padding: 16px 16px 2px 16px;
    .building-img {
      position: relative;
      height: 160px;
      border-radius: 4px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .building-info {
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      > div {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
      }
      .info1 {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        .title-text {
          flex: 1;
          text-align: start;
        }
      }
      .info2 {
        display: flex;
        flex-direction: row;
        > div {
          flex: 1;
          font-size: 12px;
          text-align: start;
        }
        .room-describle {
          color: #666;
        }
        .building-stage {
          color: #289fff;
        }
      }
      .info3 {
        display: flex;
        flex-direction: row;
        .location-text {
          text-align: start;
          font-size: 12px;
          color: #bfbfbf;
          flex: 1;
        }
      }

      .info4 {
        padding-top: 16px;
        font-size: 12px;
        color: #999;
        justify-content: space-evenly;
      }
    }
  }
}

.van-sticky {
  margin: 20px 0;
  .case-namecard {
    background-color: #ffff;
    margin: 0 16px;
    margin-bottom: 20px;
    padding: 0 16px;
    box-shadow: 0px 5px 15px rgb(229, 229, 230);
    border-radius: 8px;
    .img-name {
      height: 40px;
      padding: 5px 0;
      img {
        display: block;
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 20px;
        float: left;
      }
      .creater-info {
        float: left;
        margin-left: 10px;
        font-size: 14px;
        text-align: left;
        p {
          margin: 0;
        }
        > p:nth-child(1) {
          font-weight: 600;
        }
        > p:nth-child(2) {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .create-describe {
      font-size: 12px;
      text-align: left;
      padding: 5px 16px;
    }
  }
}
// 施工阶段进度条样式
.construction-stage {
  // background-color: #e5e5e5;
  // padding: 8px 0;
  .construction-stage-line {
    background-color: #e5e5e5;
    height: 8px;
    width: 100%;
  }

  .construction-stage-box {
    background-color: #ffff;
    height: 131px;
    padding: 16px;
    z-index: 66;
    .construction-stage-name {
      text-align: left;
      font-weight: 600;
      color: #333;
    }

    .van-step__circle {
      width: 11px;
      height: 11px;
    }

    .van-step__line {
      background: #bfbfbf;
    }

    .van-icon-success::before {
      width: 11px;
      height: 11px;
      background-color: #289fff;
      border-radius: 50%;
      color: #289fff;
    }
  }
  .steps-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .steps-name-text {
      display: flex;
      font-size: 11px;
      justify-content: flex-start;
    }
  }
}

// 施工进度\设计师\样板图片
.construction-progress {
  .progress-item-box {
    border-bottom: #f1f3f6 solid 1px;
    padding: 16px 9px 18px 15px;
    display: flex;
    flex-direction: row;
    .header-img {
      width: 48px;
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      padding-right: 5px;
      > img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .progress-info-box {
      flex: 1;
      text-align: start;
      .progress-info1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .progress-info1-name {
          color: #333;
          font-weight: 600;
        }
        .progress-info1-steps {
          color: #666;
          text-align: end;
          font-size: 12px;
        }
      }

      .progress-info4 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        > img {
          width: 90px;
          height: 90px;
          object-fit: cover;
          margin: 2px 2px;
        }
      }

      .progress-info2,
      .progress-info5 {
        color: #999;
        font-size: 12px;
      }
      .progress-info3 {
        color: #333;
      }
    }
  }
}
//传播轨迹
.track-box {
  display: flex;
  flex-direction: row;
  padding: 10px 16px 16px 17px;
  border-bottom: 0.5px solid #f1f3f6;
  .user-img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .track-details {
    flex: 1;
    .track-info {
      display: flex;
      flex-direction: row;
      > div {
        flex: 1;
        text-align: start;
      }
      > div:nth-child(2) {
        text-align: end;
      }
    }
    > div {
      line-height: 12px;
      font-weight: 12px;
      text-align: start;
      color: #999;
      padding: 4px 0;
    }
    > div:nth-child(1) {
      font-weight: 600;
      color: #333;
      font-size: 14px;
    }
  }
}
//遮罩层
.wrapperPoster {
  //margin-top: 100px;
  height: 400px;
  overflow-x: hidden;
  //position: absolute;
  position: fixed;
  top: 60px;
  //bottom: 0;
  left: 0;
  right: 0;
  .poster-details {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    overflow-x: hidden;
    > img {
      width: 250px;
      display: block;
    }
    //.text-qrcode{
    //  background-color: #ffff;
    //  display: flex;
    //  justify-content: space-between;
    //  padding: 10px;
    //  .left-text{
    //    text-align: left;
    //    p {
    //      margin: 0;
    //      padding: 0;
    //    }
    //    p:nth-child(1){
    //      font-size: 14px;
    //      font-weight: 400;
    //      line-height: 18px;
    //      color: #333333;
    //    }
    //    p:nth-child(2){
    //      font-size: 9px;
    //      color: #999999;
    //      line-height: 20px;
    //    }
    //  }
    //  .qrCodeUrl-all{
    //    position: relative;
    //    text-align: center;
    //    height: 60px;
    //    .qrCodeUrl-cls{
    //      width: 60px;
    //      height: 60px;
    //      background-color: #FFFFFF;
    //      border-radius: 30px;
    //      position: relative;
    //      top: -33px;
    //      text-align: center;
    //      img{
    //        width: 50px;
    //        border-radius: 25px;
    //        position: relative;
    //        margin-top: 50%;
    //        transform: translateY(-50%);
    //      }
    //    }
    //    >p{
    //      position: relative;
    //      font-size: 9px;
    //      font-weight: 400;
    //      color: #999999;
    //      top: -40px;
    //    }
    //  }
    //}
    .text-qrcode {
      background-color: #ffff;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      flex: 1;
      .user-info-tag {
        .user-info {
          display: flex;
          align-items: center;
          img {
            border-radius: 20px;
            width: 40px;
            height: 40px;
            object-fit: cover;
            flex-shrink: 0;
          }
          .left-text {
            text-align: left;
            //flex: 1;
            margin-left: 4px;
            p {
              margin: 0;
              padding: 0;
            }
            p:nth-child(1) {
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
              color: #333333;
            }
            p:nth-child(2) {
              font-size: 10px;
              color: #333333;
              //line-height: 20px;
            }
          }
        }
        .user-tag {
          display: flex;
          flex-wrap: wrap;
          .user-tag-item {
            margin-top: 5px;
            margin-right: 5px;
            //padding-left: 5px;
            padding: 2px 5px;
            color: #289fff;
            background-color: #e6ecff;
            font-size: 9px;
            border-radius: 4px;
          }
        }
      }

      .qrCodeUrl-all {
        //position: relative;
        //text-align: center;
        //height: 60px;
        border-left: 1px solid #f1f3f6;
        padding-left: 18px;
        margin-left: 8px;
        flex-shrink: 0;
        .qrCodeUrl-cls {
          //width: 60px;
          //height: 60px;
          background-color: #ffffff;
          border-radius: 30px;
          //position: relative;
          //top: -33px;
          //text-align: center;
          img {
            width: 60px;
            //border-radius: 25px;
            //position: relative;
            //margin-top: 50%;
            //transform: translateY(-50%);
          }
        }
        > p {
          //position: relative;
          text-align: center;
          font-size: 10px;
          font-weight: 400;
          color: #666666;
          margin: 0;
          //top: -40px;
        }
      }
    }
  }
  .posterImg-hb {
    height: 400px;
    overflow-x: hidden;
  }
}
//   分享
.share_box {
  .van-share-sheet__cancel::before {
    height: 0;
  }
  .van-share-sheet__cancel {
    height: 48px;
    width: 343px;
    background-color: #f7f7f7;
    color: #333333;
    border-radius: 5px;
    //    position: relative;
    //    transform: translateX(50%);
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .van-share-sheet__options {
    color: #333333;
    display: flex;
    padding: 0 20px;
    justify-content: space-around;
  }
}
</style>